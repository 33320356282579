body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@font-face {
    font-family: "poppins";
    src: local("poppins"),
        url(./ui/fonts/Poppins/Poppins-Medium.ttf) format("truetype");
}

@font-face {
    font-family: "poppins-bold";
    src: local("poppins-bold"),
        url(./ui/fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "walsheim";
    src: local("walsheim"),
        url(./ui/fonts/GTWalsheimPro/GTWalsheimPro-Medium.ttf)
            format("truetype");
}

* {
    font-family: poppins;
    box-sizing: border-box;
    margin: 0;

    /* scrollbar-color: #339d4a #d9d9d9; */
    scrollbar-width: thin;
}
